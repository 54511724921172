<template>
	<div class="data-content">
		<div v-show="page == 'page'">
			<div class="top-box">
				<!-- <button class="add-btn" @click="handleEditData('add')">新增</button> -->
				<div class="top-right-box">
					<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
					<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
					<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
				</div>
			</div>
			<div class="data-list-box">
				<el-table
					:data="areaList"
					:row-class-name="tableRowClassName"
					:header-cell-style="{color:'#444'}"
					style="width: 100%">
					<el-table-column
						label="NO."
						width="50">
						<template slot-scope="scope">
							<span class="no">{{scope.$index+1}}</span>
						</template>
					</el-table-column>
					<el-table-column
						label="区域名称"
						show-overflow-tooltip
						prop="name">
					</el-table-column>
					<el-table-column
						label="区域说明"
						show-overflow-tooltip
						prop="remark">
					</el-table-column>
					<el-table-column
						label="操作"
						width="120">
						<template slot-scope="scope">
							<!-- <button class="btn-blue" @click="handleEditData(scope.row)">修改</button> -->
							<button class="btn-blue" @click="getDetailDataList(scope.row.id,scope.row.name)">查看</button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-wrapper pa25">
					<Pagination
						:page.sync="pageNo"
						:pageSize.sync="pageSize"
						:total="total"
						@sizeChange="handleSizeChange"
						@currentChange="handleCurrentChange">
					</Pagination>
				</div>
			</div>
		</div>
		<div v-show="page == 'detail'">
			<div class="top-box">
				<span>省市区域管理-{{areaName}}</span>
				<button class="add-btn f-r" @click="handleEditDetail('add')" v-if="perms.includes('sys:area:add')">新增</button>
				<button class="add-btn f-r" @click="page = 'page'">返回</button>
			</div>
			<div class="data-list-box">
				<el-table
					:data="detailDataList"
					:row-class-name="tableRowClassName"
					:header-cell-style="{color:'#444'}"
					style="width: 100%">
					<el-table-column
						label="NO."
						width="50">
						<template slot-scope="scope">
							<span class="no">{{scope.$index+1}}</span>
						</template>
					</el-table-column>
					<el-table-column
						prop="name"
						label="省市名称">
					</el-table-column>
					<el-table-column
						prop="remark"
						label="省市说明">
					</el-table-column>
					<el-table-column
						label="区域名称">
						<template slot-scope="scope">
							{{scope.row.dataName}}
						</template>
					</el-table-column>
					<el-table-column
						prop="sort"
						label="排序">
					</el-table-column>
					<el-table-column
						label="操作"
						width="120">
						<template slot-scope="scope">
							<button class="btn-blue" @click="handleEditDetail(scope.row)" v-if="perms.includes('sys:area:update')">修改</button>
							<button class="btn-red" @click="handleDeleteDataDetail(scope.row)" v-if="perms.includes('sys:area:delete')">删除</button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-wrapper pa25">
					<Pagination
						:page.sync="detailPageNo"
						:pageSize.sync="detailPageSize"
						:total="detailTotal"
						@sizeChange="handleSizeChangeData"
						@currentChange="handleCurrentChangeData">
					</Pagination>
				</div>
			</div>
		</div>
		<edit-data ref="editData" @getAreaList="getAreaList"></edit-data>
		<edit-detail ref="editDetail" @getDetailDataList="getDetailDataList"></edit-detail>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import EditDetail from './components/edit-detail'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		EditDetail,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			page: 'page',
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: [],
			detailPageNo: 1,
			detailPageSize: 20,
			detailTotal: 0,
			areaName: '',
			detailDataList: [],
			currentId: '',
			areaList: []
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		this.getAreaList();
		this.getAreaList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 新增/编辑/查看
		// handleEditData(data) {
		// 	this.$refs.editData.init(data);
		// },
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前类别?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysDataDetailDelete',
					true,
					{
						code: data.code,
						id: data.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getAreaList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getAreaList();
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.getAreaList();
		},
		// 获取区域数据
		getAreaList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSizeChange() {
			this.getAreaList();
		},
		handleCurrentChange() {
			this.getAreaList();
		},
		getDetailDataList(id,name) {
			this.areaName = name;
			let that = this;
			that.$request.post(
				"sysAreaPageList",
				false,
				{
					pageNo: that.detailPageNo,
					pageSize: that.detailPageSize,
					dataId: id
				},
				function (r) {
					if (r.code == "0") {
						that.currentId = id;
						that.page = 'detail';
						that.detailDataList = r.data.list;
						that.detailTotal = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 新增/编辑/查看
		handleEditDetail(data) {
			this.$refs.editDetail.init(data,this.currentId,this.areaName);
		},
		handleSizeChangeData() {
			this.getDetailDataList(this.currentId,this.areaName);
		},
		handleCurrentChangeData() {
			this.getDetailDataList(this.currentId,this.areaName);
		},
		// 第二层删除
		handleDeleteDataDetail(data) {
			this.$confirm('是否删除当前类别?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysAreaDelete',
					true,
					{
						dataId: data.dataId,
						id: data.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getDetailDataList(data.dataId,that.areaName);
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
</style>

